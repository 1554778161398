<template>
  <v-container>
    <v-data-table :headers="headers" :items="Cameras" item-key="deviceID" sort-by="deviceID" class="elevation-1">
      <template v-slot:item.deviceName="deviceNameProps">
        <v-edit-dialog :return-value.sync="deviceNameProps.item.deviceName" large persistent @save="save_devicename(deviceNameProps.item)" @cancel="cancel_devicename(deviceNameProps.item)" @open="open_devicename(deviceNameProps.item)" @close="closed_devicename(deviceNameProps.item)">
          <div>
            {{ deviceNameProps.item.deviceName }}
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </div>
          <template v-slot:input>
            <div class="mt-4 title">名前編集</div>
            <v-text-field v-model="deviceNameProps.item.deviceName" :rules="[required]" label="Edit" single-line counter autofocus></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click.stop="clickEdit(item)">詳細設定</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog_settings" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">詳細設定</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.deviceName" label="デバイス名" :rules="[required]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.description" label="備考"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <th>デバイスID</th>
                  <th>{{ editedItem.deviceID }}</th>
                </tr>
                <tr>
                  <th>デバイスタイプ</th>
                  <th>{{ editedItem.deviceType }}</th>
                </tr>
                <tr>
                  <th>HW Ver.</th>
                  <th>{{ editedItem.hwVersion }}</th>
                </tr>
                <tr>
                  <th>SW Ver.</th>
                  <th>{{ editedItem.swVersion }}</th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="editedItem.swVerUpTrigger === 'Up'">
          <v-container>
            <v-list-item-content>
              <v-list-item-subtitle>
                ファームウェアアップデートがあります
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                本機の電源をONにした状態で実行して下さい
              </v-list-item-subtitle>
              <v-btn color="blue darken-1" @click="click_firmup(editedItem)">
                ファームウェアアップデート実行
              </v-btn>
            </v-list-item-content>
          </v-container>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel_devicesetting(editedItem)">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="save_devicesetting(editedItem)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_firmup" max-width="400px">
      <v-card>
        <v-card-title class="text-h5">ファームウェアアップデートを実行します</v-card-title>
        <v-card-title class="text-h6">本機が再起動します。運用中でないことを確認して実行して下さい。</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="execute_firmup(editedItem)">
            実行
          </v-btn>
          <v-btn color="blue darken-1" text @click="cancel_firmup(editedItem)">
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { API, graphqlOperation } from "aws-amplify";
  import { Auth } from "aws-amplify";
  import { updateDevice } from "../graphql/mutations";
  import { listDevices } from "../graphql/queries";

  window.LOG_LEVEL = "VERBOSE";

  export default {
    props: ["Cameras"],
    data: () => ({
      devices: [],
      content: "",
      deviceID: "",
      subscription: {},
      error: "",
      singleSelect: true,
      selected: [],
      snack: false,
      snackColor: "",
      snackText: "",
      dialog_settings: false,
      dialog_firmup: false,
      ipSetting: "auto",
      ipAuto: true,
      max25chars: (v) => v.length <= 25 || "Input too long!",
      required: value => !!value || "必須入力項目です",
      limit_length: value => value.length <= 30 || "30文字以内で入力してください",
      devIDRules: [
        value => !!value || '必須入力項目です',
        value => {
          const pattern = /([0-9a-f]{2}[:]){5}([0-9a-f]{2})/;
          return pattern.test(value) || '不正な形式です。xx:xx:xx:xx:xx:xxの形式で入力して下さい。';
        },
      ],
      headers: [
        { text: "ID", value: "deviceID" },
        { text: "デバイス名", value: "deviceName" },
        { text: "備考", value: "description" },
        { text: "", value: "actions", sortable: false },
      ],
      editedItem: {
        deviceID: "",
        deviceName: "",
        description: "",
        deviceType: "",
        deviceGroup: "",
        serialNo: "",
        swVersion: "",
        hwVersion: "",
        ipChgStatus: "",
        ethIpAddress: "",
        ethSubnet: "",
        ethGateway: "",
        ethDns: "",
        swVerUpTrigger: "",
        createdAt: "",
        updatedAt: "",
        tallyIN: {
          items: [{
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
            {
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
            {
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
            {
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
          ],
        },
        tallyOUT: {
          items: [{
            createdAt: "",
            description: "",
            deviceID: "",
            portID: "",
            portName: "",
            portPWM: "",
            sourceDeviceID: "",
            sourcePortID: "",
            updatedAt: "",
          }, ],
        },
      },
    }),

    watch: {
      ipSetting: function(val) {
        if (val === "auto") {
          this.ipAuto = true;
        }
        else {
          this.ipAuto = false;
        }
      },
    },

    methods: {
      clickEdit(item) {
        console.log(item);
        this.editedItem = Object.assign({}, item);
        this.dialog_settings = true;
      },
      save_devicename(item) {
        console.log(item);
        this.mutation_devicename(item);
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      },
      cancel_devicename(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
      },
      open_devicename(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "info";
        this.snackText = "Dialog opened";
      },
      closed_devicename(item) {
        console.log(item);
        console.log("Dialog closed");
      },
      async mutation_devicename(item) {

        const messageUpdateDevice = {
          deviceID: item.deviceID,
          deviceName: item.deviceName,
        };

        console.log("message", messageUpdateDevice);

        await API.graphql(graphqlOperation(updateDevice, { input: messageUpdateDevice })).catch((error) =>
          console.error("updateDevice", error)
        );
        await this.fetch();
      },

      save_devicesetting(item) {
        console.log(item);
        this.mutation_devicesetting(item);
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
        this.dialog_settings = false;
      },
      cancel_devicesetting(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
        this.dialog_settings = false;
      },

      async mutation_devicesetting(item) {

        const messageUpdateDevice = {
          deviceID: item.deviceID,
          deviceName: item.deviceName,
          description: item.description,
        };

        console.log("message", messageUpdateDevice);

        await API.graphql(graphqlOperation(updateDevice, { input: messageUpdateDevice })).catch((error) =>
          console.error("updateDevice", error)
        );
        await this.fetch();
      },
      
      click_firmup(item) {
        this.dialog_firmup = true;
        console.log(item);
      },
      execute_firmup(item) {
        this.mutation_execute_firmup(item);
        this.dialog_firmup = false;
        console.log(item);
      },
      cancel_firmup(item) {
        this.dialog_firmup = false;
        console.log(item);
      },

      async mutation_execute_firmup(item) {
        console.log('ipsetting');
        console.log(item);

        if (item.swVerUpTrigger == "Up") {
          this.swVerUpTrigger = "ExecUp";

          const messageExecuteFirmUp = {
            deviceID: item.deviceID,
            swVerUpTrigger: this.swVerUpTrigger
          };

          console.log("messageExecuteFirmUp", messageExecuteFirmUp);


          await API.graphql(graphqlOperation(updateDevice, { input: messageExecuteFirmUp })).catch(
            (error) => {
              console.error("updateIPSetting", error);
              this.snack = true;
              this.snackColor = "error";
              this.snackText = "Error";
            }
          );
          await this.fetch();
        }
      },
      
      async fetch() {

        const items = await API.graphql(graphqlOperation(listDevices)).catch((err) =>
          console.error("listDevices", err)
        );

        console.log("items", items);
        this.devices = items.data.listDevices.items;
        console.log("this.devices", this.devices);
        //switcherとcameraの振り分け
        var i = 0;
        for (var deviceIndex in this.devices) {
          console.log(deviceIndex);
          if (this.devices[deviceIndex].deviceType === "camera") {
            this.Cameras[i] = this.devices[deviceIndex];
            i++;
            console.log("this.Cameras", this.Cameras);
          }
        }

        this.ObjArraySort(this.Cameras, "deviceName");

        console.log("Cameras", this.Cameras);
      },
      ObjArraySort(ary, key, order) {
        var reverse = 1;
        if (order && order.toLowerCase() == "desc") reverse = -1;
        ary.sort(function(a, b) {
          if (a[key] < b[key]) return -1 * reverse;
          else if (a[key] == b[key]) return 0;
          else return 1 * reverse;
        });
      },
      subscribe() {},
    },
    mounted() {
      //this.fetch();
    },
    async created() {
      this.userName = (await Auth.currentAuthenticatedUser()).username;
      console.log("this.userName", this.userName);
      //this.tenantID = (await Auth.currentAuthenticatedUser())["attributes"][
      //  "custom:tenantID"
      //];
      //this.fetch();
      this.subscribe();
    },
    beforeDestroy() {
      //this.onUpdateDeviceSubscription.unsubscribe();
    },
  };
</script>
