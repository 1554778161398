<template>
  <v-container>
    <v-data-table :headers="headers" :items="Switchers" item-key="deviceID" sort-by="deviceID" class="elevation-1">
      <template v-slot:item.deviceName="deviceNameProps">
        <v-edit-dialog :return-value.sync="deviceNameProps.item.deviceName" large persistent @save="save_devicename(deviceNameProps.item)" @cancel="cancel_devicename(deviceNameProps.item)" @open="open_devicename(deviceNameProps.item)" @close="closed_devicename(deviceNameProps.item)">
          <div>
            {{ deviceNameProps.item.deviceName }}
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </div>
          <template v-slot:input>
            <div class="mt-4 title">名前編集</div>
            <v-text-field v-model="deviceNameProps.item.deviceName" :rules="[max25chars]" label="Edit" single-line counter autofocus></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click.stop="clickEdit(item)">詳細設定</v-btn>
        <v-btn @click.stop="clickIPEdit(item)">有線LAN IP設定</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog_settings" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">詳細設定</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.deviceName" label="デバイス名"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.description" label="備考"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.tallyIN.items[0].portName" label="ポートA"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.tallyIN.items[1].portName" label="ポートB"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.tallyIN.items[2].portName" label="ポートC"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field dense v-model="editedItem.tallyIN.items[3].portName" label="ポートD"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <th>デバイスID</th>
                  <th>{{ editedItem.deviceID }}</th>
                </tr>
                <tr>
                  <th>デバイスタイプ</th>
                  <th>{{ editedItem.deviceType }}</th>
                </tr>
                <tr>
                  <th>HW Ver.</th>
                  <th>{{ editedItem.hwVersion }}</th>
                </tr>
                <tr>
                  <th>SW Ver.</th>
                  <th>{{ editedItem.swVersion }}</th>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="editedItem.swVerUpTrigger === 'Up'">
          <v-container>
            <v-list-item-content>
              <v-list-item-subtitle>
                ファームウェアアップデートがあります
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                本機の電源をONにした状態で実行して下さい
              </v-list-item-subtitle>
              <v-btn color="blue darken-1" @click="click_firmup(editedItem)">
                ファームウェアアップデート実行
              </v-btn>
            </v-list-item-content>
          </v-container>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel_devicesetting(editedItem)">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="save_devicesetting(editedItem)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_ipsetting" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">有線LAN接続時 IPアドレス設定</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="editedItem.ipChgStatus">
                  <v-radio label="自動（DHCP）" value="Auto"></v-radio>
                  <v-radio label="手動（手入力）" value="Manual"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-bind:disabled="editedItem.ipChgStatus == 'Auto'" dense v-model="editedItem.ethIpAddress" label="IPアドレス" :rules="ipRules"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-bind:disabled="editedItem.ipChgStatus == 'Auto'" dense v-model="editedItem.ethSubnet" label="サブネットマスク"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-bind:disabled="editedItem.ipChgStatus == 'Auto'" dense v-model="editedItem.ethGateway" label="ルーター"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-bind:disabled="editedItem.ipChgStatus == 'Auto'" dense v-model="editedItem.ethDns" label="DNSサーバ"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel_ipsetting(editedItem)">
            キャンセル
          </v-btn>
          <v-btn color="blue darken-1" text @click="execute_ipsetting(editedItem)">
            実行
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_ipChgConfirm" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">IPアドレス設定を実行します</v-card-title>
        <v-card-title class="text-h6">親機が再起動します。運用中でないことを確認して実行して下さい。</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel_ipsetting(editedItem)">キャンセル</v-btn>
          <v-btn color="blue darken-1" text @click="confirm_ipsetting(editedItem)">実行</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_firmup" max-width="400px">
      <v-card>
        <v-card-title class="text-h5">ファームウェアアップデートを実行します</v-card-title>
        <v-card-title class="text-h6">本機が再起動します。運用中でないことを確認して実行して下さい。</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="execute_firmup(editedItem)">
            実行
          </v-btn>
          <v-btn color="blue darken-1" text @click="cancel_firmup(editedItem)">
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { API, graphqlOperation } from "aws-amplify";
  import { Auth } from "aws-amplify";
  import { updateDevice } from "../graphql/mutations";
  import { updateTallyIN } from "../graphql/mutations";
  import { listDevices } from "../graphql/queries";

  window.LOG_LEVEL = "VERBOSE";

  export default {
    props: ["Switchers"],
    data: () => ({
      devices: [],
      content: "",
      deviceID: "",
      subscription: {},
      error: "",
      singleSelect: true,
      selected: [],
      snack: false,
      snackColor: "",
      snackText: "",
      dialog_settings: false,
      dialog_ipsetting: false,
      dialog_ipChgConfirm: false,
      dialog_firmup: false,
      ipChgStatus: "Auto",
      ipRules: [
        v => !!v || '必須入力項目です',
        v => {
          const pattern = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/
          return pattern.test(v) || '不正な形式です。xxx.xxx.xxx.xxxの形式で入力して下さい。'
        },
      ],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      headers: [
        { text: "ID", value: "deviceID" },
        { text: "デバイス名", value: "deviceName" },
        { text: "備考", value: "description" },
        { text: "", value: "actions", sortable: false },
      ],
      editedItem: {
        deviceID: "",
        deviceName: "",
        description: "",
        deviceType: "",
        deviceGroup: "",
        serialNo: "",
        swVersion: "",
        hwVersion: "",
        ipChgStatus: "",
        ethIpAddress: "",
        ethSubnet: "",
        ethGateway: "",
        ethDns: "",
        swVerUpTrigger: "",
        createdAt: "",
        updatedAt: "",
        tallyIN: {
          items: [{
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
            {
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
            {
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
            {
              createdAt: "",
              description: "",
              deviceID: "",
              input: "",
              portID: "",
              portName: "",
              updatedAt: "",
            },
          ],
        },
        tallyOUT: {
          items: [{
            createdAt: "",
            description: "",
            deviceID: "",
            portID: "",
            portName: "",
            portPWM: "",
            sourceDeviceID: "",
            sourcePortID: "",
            updatedAt: "",
          }, ],
        },
      },
    }),

    watch: {},

    methods: {
      clickEdit(item) {
        console.log(item);
        this.editedItem = Object.assign({}, item);
        this.dialog_settings = true;
      },
      clickIPEdit(item) {
        this.fetch();
        console.log(item);
        this.editedItem = Object.assign({}, item);
        this.dialog_ipsetting = true;
      },
      save_devicename(item) {
        console.log(item);
        this.mutation_devicename(item);
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      },
      cancel_devicename(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
      },
      open_devicename(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "info";
        this.snackText = "Dialog opened";
      },
      closed_devicename(item) {
        console.log(item);
        console.log("Dialog closed");
      },
      async mutation_devicename(item) {

        const messageUpdateDevice = {
          deviceID: item.deviceID,
          deviceName: item.deviceName,
        };

        console.log("message", messageUpdateDevice);

        await API.graphql({
          query: updateDevice,
          variables: { input: messageUpdateDevice },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).catch((error) => {
          console.error("updateDevice", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });
        await this.fetch();
      },

      save_devicesetting(item) {
        console.log(item);
        this.mutation_devicesetting(item);
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
        this.dialog_settings = false;
      },
      cancel_devicesetting(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
        this.dialog_settings = false;
      },

      execute_ipsetting(item) {
        console.log(item);
        this.dialog_ipChgConfirm = true;
      },
      cancel_ipsetting(item) {
        console.log(item);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Canceled";
        this.dialog_ipsetting = false;
        this.dialog_ipChgConfirm = false;
      },
      confirm_ipsetting(item) {
        this.mutation_ipsetting(item);
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
        this.dialog_ipsetting = false;
        this.dialog_ipChgConfirm = false;
      },

      async mutation_devicesetting(item) {

        const messageUpdateDevice = {
          deviceID: item.deviceID,
          deviceName: item.deviceName,
          description: item.description,
        };

        const messageUpdateTallyIN_portA = {
          deviceID: item.deviceID,
          portID: 1,
          portName: item.tallyIN.items[0].portName,
          //description: item.tallyIN.items[0].description,
        };

        const messageUpdateTallyIN_portB = {
          deviceID: item.deviceID,
          portID: 2,
          portName: item.tallyIN.items[1].portName,
          //description: item.tallyIN.items[1].description,
        };

        const messageUpdateTallyIN_portC = {
          deviceID: item.deviceID,
          portID: 3,
          portName: item.tallyIN.items[2].portName,
          //description: item.tallyIN.items[2].description,
        };

        const messageUpdateTallyIN_portD = {
          deviceID: item.deviceID,
          portID: 4,
          portName: item.tallyIN.items[3].portName,
          //description: item.tallyIN.items[3].description,
        };

        console.log("message", messageUpdateDevice);

        await API.graphql({
          query: updateDevice, 
          variables: { input: messageUpdateDevice },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).catch((error) => {
          console.error("updateDevice", error);
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error";
        });

        await API.graphql({
          query: updateTallyIN, 
          variables: { input: messageUpdateTallyIN_portA },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).catch(
          (error) => {
            console.error("updateTallyIN_portA", error);
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Error";
          }
        );

        await API.graphql({
          query: updateTallyIN,
          variables: { input: messageUpdateTallyIN_portB },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).catch(
          (error) => {
            console.error("updateTallyIN_portB", error);
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Error";
          }
        );

        await API.graphql({
          query: updateTallyIN,
          variables: { input: messageUpdateTallyIN_portC },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).catch(
          (error) => {
            console.error("updateTallyIN_portC", error);
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Error";
          }
        );

        await API.graphql({
          query: updateTallyIN,
          variables: { input: messageUpdateTallyIN_portD },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).catch(
          (error) => {
            console.error("updateTallyIN_portD", error);
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Error";
          }
        );

        await this.fetch();
      },

      async mutation_ipsetting(item) {
        console.log('ipsetting');
        console.log(item);

        if (item.ipChgStatus == "Auto") {
          this.ipChgStatus = "CHGAuto";
        }
        else {
          this.ipChgStatus = "CHGManual";
        }

        const messageUpdateIPSetting = {
          deviceID: item.deviceID,
          ipChgStatus: this.ipChgStatus,
          ethIpAddress: item.ethIpAddress,
          ethSubnet: item.ethSubnet,
          ethGateway: item.ethGateway,
          ethDns: item.ethDns
        };

        console.log("messageUpdateIPSetting", messageUpdateIPSetting);


        await API.graphql({
          query: updateDevice,
          variables: { input: messageUpdateIPSetting },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).catch(
          (error) => {
            console.error("updateIPSetting", error);
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Error";
          }
        );

        await this.fetch();
      },

      click_firmup(item) {
        this.dialog_firmup = true;
        console.log(item);
      },
      execute_firmup(item) {
        this.mutation_execute_firmup(item);
        this.dialog_firmup = false;
        console.log(item);
      },
      cancel_firmup(item) {
        this.dialog_firmup = false;
        console.log(item);
      },

      async mutation_execute_firmup(item) {
        console.log('ipsetting');
        console.log(item);

        if (item.swVerUpTrigger == "Up") {
          this.swVerUpTrigger = "ExecUp";

          const messageExecuteFirmUp = {
            deviceID: item.deviceID,
            swVerUpTrigger: this.swVerUpTrigger
          };

          console.log("messageExecuteFirmUp", messageExecuteFirmUp);


          await API.graphql({
            query: updateDevice,
            variables: { input: messageExecuteFirmUp },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }).catch(
            (error) => {
              console.error("updateIPSetting", error);
              this.snack = true;
              this.snackColor = "error";
              this.snackText = "Error";
            }
          );
          await this.fetch();
        }
      },

      async fetch() {

        const items = await API.graphql(graphqlOperation(listDevices)).catch((err) =>
          console.error("listDevices", err)
        );

        console.log("items", items);
        this.devices = items.data.listDevices.items;
        console.log("this.devices", this.devices);
        //switcherとcameraの振り分け
        var i = 0;
        for (var deviceIndex in this.devices) {
          console.log(deviceIndex);
          if (this.devices[deviceIndex].deviceType === "switcher") {
            this.Switchers[i] = this.devices[deviceIndex];
            i++;
            console.log("this.Switchers", this.Switchers);
          }
        }

        this.ObjArraySort(this.Switchers, "deviceName");

        console.log("Switchers", this.Switchers);
      },
      ObjArraySort(ary, key, order) {
        var reverse = 1;
        if (order && order.toLowerCase() == "desc") reverse = -1;
        ary.sort(function(a, b) {
          if (a[key] < b[key]) return -1 * reverse;
          else if (a[key] == b[key]) return 0;
          else return 1 * reverse;
        });
      },
      subscribe() {},
    },
    mounted() {
      //this.fetch();
    },
    async created() {
      this.userName = (await Auth.currentAuthenticatedUser()).username;
      console.log("this.userName", this.userName);
      this.group = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'][0];
      console.log("this.group", this.group);
      //this.tenantID = (await Auth.currentAuthenticatedUser())["attributes"][
      //  "custom:tenantID"
      //];
      //this.fetch();
      this.subscribe();
    },
    beforeDestroy() {
      //this.onUpdateDeviceSubscription.unsubscribe();
    },
  };
</script>
